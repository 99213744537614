import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout/Layout"

import Seo from "../../components/Seo"

export default function Confidentialite() {
  return (
    <Layout>
      <Seo title="Privacy" index={false} follow={false} />
      <section className="container">
        <div className="shorten-text center-block justify">
          <div>
            <Link
              to="/privacy/fr/"
              className="text-center"
              style={{ display: "block", marginTop: "2rem" }}
              title="View this page in french"
            >
              🇫🇷
            </Link>
            {/* Données recueillies par ce site */}
            <h1 className="title text-center uppercase italic">
              Data collected by this site
            </h1>
            <p>
              When browsing this site, access logs are collected by our host
              Netlify Inc., whose head office is located at 2325 3rd Street,
              Suite 215, San Francisco, California 94107 at United States. These
              data are kept for less than 30 days and are not used for marketing
              purposes. These data consist of your IP address, the operating
              system of your hardware and its version, your web browser and its
              version, from the address URL consulted and the URL address of the
              previous page (URL of reference).
            </p>
          </div>
          <div>
            <h2 className="title text-center uppercase italic">
              Person responsible for data processing
            </h2>
            <ul>
              {/* Responsable du traitement des données */}
              <li>
                Person responsible for data processing :<p>Pierre PHILIPPON</p>
              </li>
              <li>
                Address
                <p>136 rue de la Bruyère, 78300 POISSY</p>
              </li>
              <li>
                Email
                <p>
                  <a
                    href="mailto:pierre@urule.fr"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="legal-link_style"
                  >
                    pierre@urule.fr
                  </a>
                </p>
              </li>
              <li>
                Phone{" "}
                <p>
                  <a
                    href="tel:33637102262"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="legal-link_style"
                  >
                    +33 6 37 10 22 62
                  </a>
                </p>
              </li>
            </ul>
            <p>
              The data collected will be communicated to the following person
              only : Pierre Philippon, as data controller.
            </p>
            <p>It will be stored for 6 months.</p>
            <p>
              You can access and correct data, request their erasure or exercise
              your right to limit the processing of your data. You can withdraw
              your consent to the processing of your data, oppose the processing
              your data and exercising your right to portability of your data.
              Visit the website{" "}
              <a
                href="http://www.cnil.fr"
                rel="noopener noreferrer"
                target="_blank"
                className="legal-link_style"
              >
                cnil.fr
              </a>{" "}
              for more information about your rights.
            </p>
            <p>
              To exercise these rights or for any questions about the processing
              of your data in this device, you can contact the data controller
              designated in paragraph 2.
            </p>
            <p>
              If you believe, after contacting us, that your privacy rights are
              not respected, you can send a complaint to the CNIL.
            </p>

            {/* End of privacy
          -------------------------------------------------- */}
          </div>
          <Link to="/" className="accueil-link legal-link_style">
            Home
          </Link>
        </div>
      </section>
    </Layout>
  )
}
